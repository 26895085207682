export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    update: "",
    updates: "",
  },

  getters: {
    update: (state) => state.update,
    updates: (state) => state.updates,
  },

  mutations: {
    setupdate: (state, update) => (state.update = update),
    setupdates: (state, updates) => (state.updates = updates),
  },

  actions: {
    async create_update({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_update");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/updates/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setupdates", response.data.data);
              toast.success("update Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_update({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_update");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/updates/update", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setupdates", response.data.data);
              toast.success("update Updated Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_update({ commit }, {data, id}) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_update");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/updates/delete/"+ id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setupdates", response.data.data);
              toast.success("update Deleted Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_updates({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_updates");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/updates?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setupdates", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },


    async get_update_details({ commit }, {data, id}) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_update_details");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/updates/"+ id, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setupdate", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
