export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    subject: "",
    subjects: "",
  },

  getters: {
    subject: (state) => state.subject,
    subjects: (state) => state.subjects,
  },

  mutations: {
    setsubject: (state, subject) => (state.subject = subject),
    setsubjects: (state, subjects) => (state.subjects = subjects),
  },

  actions: {
    async create_subject({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_subject");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subjects/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubjects", response.data.data);
              toast.success("subject Created Succefully");
            
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_subject({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_subject");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subjects/update", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubjects", response.data.data);
              toast.success("subject Updated Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_subject({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_subject");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subjects/delete", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubjects", response.data.data);
              toast.success("subject Deleted Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_subjects({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_subjects");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subjects?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubjects", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
