export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    subject_level: "",
    subject_levels: "",
  },

  getters: {
    subject_level: (state) => state.subject_level,
    subject_levels: (state) => state.subject_levels,
  },

  mutations: {
    setsubject_level: (state, subject_level) => (state.subject_level = subject_level),
    setsubject_levels: (state, subject_levels) => (state.subject_levels = subject_levels),
  },

  actions: {
    async create_subject_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_subject_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/level_subjects/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubject_levels", response.data.data);
              toast.success("subject_level Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  toast.error(error.response.data.msg);
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_subject_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_subject_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subject_levels/update", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubject_levels", response.data.data);
              toast.success("subject_level Updated Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_subject_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_subject_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/subject_levels/delete", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubject_levels", response.data.data);
              toast.success("subject_level Deleted Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_subject_levels({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_subject_levels");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/level_subjects?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setsubject_levels", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
