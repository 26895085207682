import store from "@/store";

const adminguard = (to, from, next) => {
  if(localStorage.getItem('stichatapptoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.role == 'admin'){
      return next()
  }else{
      return next({ name: 'login' })
  }
};

let routes = [
  {
    path: "/",
    props: true,
    beforeEnter: adminguard,
    component: () => import("@/views/app/main.vue"),
    title: "",

    children: [
      {
        path: "/",
        name: "welcome",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/dash.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/users",
        name: "users",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/users/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
    
      ///updates
      {
        path: "/update",
        name: "update",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/update/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },

      {
        path: "/show-messages/:id",
        name: 'show_messages',
        component: () => import("@/views/app/update/show.vue"),
        title: "",
      },


      {
        path: "/groups",
        name: "groups",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/groups/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/reports",
        name: "reports",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/report/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/analysis",
        name: "analysis",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/analysis/index.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/levels",
        name: "levels",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/settings/levels.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/subject-levels",
        name: "subect-levels",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/settings/subject_levels.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
      {
        path: "/subjects",
        name: "subects",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/settings/subjects.vue"),
        meta: {
          title: "Manage Ongoing Orders and Create New ones",
        },
      },
    ],
  },
  {
    path: "/about",
    name: 'about',
    component: () => import("@/views/app/about/about.vue"),
    title: "",
  },

  {
    path: "/private-policy",
    name: 'private-policy',
    component: () => import("@/views/app/about/private_policy.vue"),
    title: "",
  },
  {
    path: "/terms-and-conditions",
    name: 'terms-and-conditions',
    component: () => import("@/views/app/about/terms_and_conditions.vue"),
    title: "",
  }
];
export default routes;
