export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
// import axios form "../"
const toast = useToast();

export default {
  state: {
    authuser: "",
    authlocation: "",
    isAuth: "",
    returnvalue: "",
    userip: "",
    clientlocation: "",
    clientdevice: "",
    user:"",
    users:""
  },

  getters: {
    authuser: (state) => state.authuser,
    authlocation: (state) => state.authlocation,
    returnvalue: (state) => state.returnvalue,
    isAuth: (state) => !!state.authuser,
    userip: (state) => state.userip,
    clientlocation: (state) => state.clientlocation,
    clientdevice: (state) => state.clientdevice,
    user: (state) => state.user,
    users: (state) => state.users,

  },

  mutations: {
    setauthuser: (state, authuser) => (state.authuser = authuser),
    setauthlocation: (state, authlocation) =>
      (state.authlocation = authlocation),
    setisAuth: (state, isAuth) => (state.isAuth = isAuth),
    setreturnvalue: (state, returnvalue) => (state.returnvalue = returnvalue),
    setuserip: (state, userip) => (state.userip = userip),
    setclientlocation: (state, clientlocation) =>
      (state.clientlocation = clientlocation),
    setclientdevice: (state, clientdevice) =>
      (state.clientdevice = clientdevice),

      setuser: (state, user) => (state.user = user),
      setusers: (state, users) => (state.users = users),
  },

  actions: {
    getuseripaddress({ commit }) {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          commit("setuserip", ip);
        });
    },

    getuserlocation({ commit, getters }) {
      console.log(getters.userip);
      const url = "https://ipapi.co/" + getters.userip + "/json/";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          a: 10,
          b: 20,
        }),
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          commit("setclientlocation", data);
        });
    },

    async loginuser({ commit }, data) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "loginuser");
        return new Promise((resolve, reject) => {
          axios.post("/auth/admin/login", data)
            .then((response) => {
              commit("setloader", false);
              if (response.data.res) {
                localStorage.setItem("stichatapptoken" + response.data.res.id, response.data.authtoken.accessToken);
                commit('setauthuser', response.data.res)
                resolve(response);
              }
            })
            .catch((error) => {
              commit("setloader", false);
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    // toast.error(error.response.data.msg)
                  } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                  }
                }
              }
              reject(error);
            });
        });
      },

      async create_user({ commit }, data) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "create_user");
        return new Promise((resolve, reject) => {
          axios
            .post("/admin/levels/create", data)
            .then((response) => {
              commit("setloader", false);
              if (response.data.data) {
                commit("setlevels", response.data.data);
                toast.success("Level Created Succefully");
                resolve(response);
              }
            })
            .catch((error) => {
              commit("setloader", false);
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    // toast.error(error.response.data.msg)
                  } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                  }
                }
              }
              reject(error);
            });
        });
      },

      async get_users({ commit }, { data, page }) {
        commit("seterror", "");
        commit("setmsg", "");
        commit("setloader", "get_users");
        return new Promise((resolve, reject) => {
          axios
            .post("/admin/levels?page=" + page, data)
            .then((response) => {
              commit("setloader", false);
              if (response.data.data) {
                commit("setlevels", response.data.data);
                resolve(response);
              }
            })
            .catch((error) => {
              commit("setloader", false);
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.msg) {
                    commit("seterror", error.response.data.msg);
                    // toast.error(error.response.data.msg)
                  } else if (error.response.data.message) {
                    commit("seterrors", error.response.data.message);
                  }
                }
              }
              reject(error);
            });
        });
      },

}


   
};
