export const namespaced = true;
import store from "@/store";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  state: {
    level: "",
    levels: "",
  },

  getters: {
    level: (state) => state.level,
    levels: (state) => state.levels,
  },

  mutations: {
    setlevel: (state, level) => (state.level = level),
    setlevels: (state, levels) => (state.levels = levels),
  },

  actions: {
    async create_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "create_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels/create", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setlevels", response.data.data);
              toast.success("Level Created Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async update_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "update_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels/update", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setlevels", response.data.data);
              toast.success("Level Updated Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async delete_level({ commit }, data) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "delete_level");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels/delete", data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setlevels", response.data.data);
              toast.success("Level Deleted Succefully");
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },

    async get_levels({ commit }, { data, page }) {
      commit("seterror", "");
      commit("setmsg", "");
      commit("setloader", "get_levels");
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/levels?page=" + page, data)
          .then((response) => {
            commit("setloader", false);
            if (response.data.data) {
              commit("setlevels", response.data.data);
              resolve(response);
            }
          })
          .catch((error) => {
            commit("setloader", false);
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.msg) {
                  commit("seterror", error.response.data.msg);
                  // toast.error(error.response.data.msg)
                } else if (error.response.data.message) {
                  commit("seterrors", error.response.data.message);
                }
              }
            }
            reject(error);
          });
      });
    },
  },
};
