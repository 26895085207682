import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import levels  from './modules/levels'
import subjects from './modules/subjects'
import subject_levels from './modules/subject_levels'
import communication from './modules/communication'
export default createStore({
  modules: {
    auth, 
    utils,
    levels,
    subjects,
    subject_levels,
    communication
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
